@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply rounded-md border border-transparent bg-nord7 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-nord8 focus:outline-none focus:ring-2 focus:ring-nord7 focus:ring-offset-2 focus:ring-offset-gray-800
  }
  .btn-secondary {
    @apply rounded-md border border-gray-300 bg-white px-4 py-2 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-nord7 focus:ring-offset-2
  }
  .bullet-item {
    @apply h-4 w-4 border-gray-300 text-nord7 focus:ring-nord7
  }
  .link {
    @apply text-nord10 underline
  }
}